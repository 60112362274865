import http from './axios';

class PortalService {
  getPortals() {
    return http.get(`/portal`);
  }

  getPortalSettings(id) {
    return http.get(`/portal/${id}`);
  }

  updatePortalSettings(id, payload) {
    return http.put(`/portal/${id}`, payload);
  }

  updatePortalSettingsSchedule(id, payload) {
    return http.put(`/portal/${id}/schedule`, payload);
  }

  updatePortalSettingsWebhook(id, payload) {
    return http.post(`/portal/${id}/webhook`, payload);
  }

  updatePortalSettingsData(id, payload) {
    return http.post(`/portal/${id}/data`, payload);
  }

  updatePortalSettingsLogin(id, payload) {
    return http.post(`/portal/${id}/login`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updatePortalDesign(id, payload) {
    return http.post(`/portal/${id}/design`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getPortalInsights(id, filters) {
    return http.get(`/portal/${id}/insights`, { params: filters });
  }

  updatePortalClient(siteId, client_id, payload) {
    return http.put(`/portal/${siteId}/client/${client_id}`, payload);
  }

  getPublicPortalSettings(id) {
    return http.get(`/public/portal/${id}`);
  }

  authorizeClient(id, payload) {
    return http.post(`/public/portal/${id}/authorize`, payload);
  }

  mailClient(payload) {
    return http.post(`/public/portalclient`, payload);
  }

  getClient(token) {
    return http.get(`/public/portalclient/${token}`);
  }

  updatePortalSettingsAnalytics(id, payload) {
    return http.post(`/portal/${id}/analytics`, payload);
  }
}

export default new PortalService();
