export default {
  "admin": {
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal all-time"])},
    "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
    "config": {
      "canChangeWifiSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi-instellingen wijzigen?"])},
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je deze config wilt verwijderen?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config verwijderen"])}
      },
      "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een Omada bestand."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
      "titleModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
      "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een Unifi bestand"])}
    },
    "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
    "customer": {
      "addCustomerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
      "addCustomersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een bedrijf toe"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak locatie aan"])},
      "addLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
      "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig details"])},
      "inviteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "linkStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link aan Stripe"])},
      "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie onbekent"])},
      "manageLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer links"])},
      "manageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers beheren"])},
      "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De aangemaakte locatie zal hetzelfde adres hebben als ingevuld bij de klant"])},
      "noCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bedrijven gevonden"])},
      "noInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen uitnodigingen"])},
      "noLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen locaties gevonden"])},
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer bedrijf"])},
      "selectCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bedrijf"])},
      "selectExistingCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestaand bedrijf"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer locatie"])},
      "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een plan"])},
      "selectProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer provider"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer locatie type"])},
      "stripeLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe linked"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je dit wilt verwijderen?"])},
      "freeTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free tier"])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "selectCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer of deze locatie CaaS is"])},
      "caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity as a Service (CaaS)"])},
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demomodus inschakelen (bijvoorbeeld PoC)"])},
      "accountAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik het factuur-e-mailadres voor het account"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een account aan voor amplr.cloud"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een verbindingstype"])}
    },
    "dashboard": {
      "allGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in orde"])},
      "controllers": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])}
      },
      "locations": {
        "newLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar voor configuratie"])}
      },
      "portal": {
        "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal"])}
      }
    },
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestellen"])},
    "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
    "installLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar voor installatie"])},
    "location": {
      "chooseConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies het type configuratie"])},
      "chooseControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies netwerkcontrollers"])},
      "chooseHardwareVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de verschillende toestelfabrikanten"])},
      "chooseOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de Omada controller"])},
      "choosePortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestaande portal"])},
      "chooseUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de Unifi controller"])},
      "config": {
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin met het configureren van controllers"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratietype"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "wifiConfigDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met deze configuratie kun je de wifi-namen of wachtwoorden niet wijzigen. Ga alsjeblieft verder zonder."])},
        "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draadloze netwerken"])},
        "step6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
      },
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze locatie wilt archiveren? \nLet op: de livegegevens worden ook gearchiveerd"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie archiveren/pauzeren"])}
      },
      "copyPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer bestaande portal instellingen"])},
      "createNewLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak nieuwe locatie aan voor dit bedrijf"])},
      "createPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create portal"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie details"])},
      "existingPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaand portaal"])},
      "finish": {
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
        "chooseAccessoire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies product"])},
        "chooseAccessoires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke materialen om af te werken zijn gebruikt"])},
        "chooseEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke patchkast is gebruikt?"])},
        "discoveredDevices": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toestellen zijn actief op deze locatie en zullen opgenomen worden in de werkbon."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikte toestellen"])}
        },
        "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "noEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen patchkast"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])}
      },
      "finishConfig": {
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideren van installatie"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideren van facturatie"])},
        "addInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installateur toevoegen"])}
      },
      "label": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])}
      },
      "peplinkSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de Peplink instellingen"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak captive portal aan"])},
      "selectApVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer AP fabrikant"])},
      "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bedrijf"])},
      "selectConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een configuratietype"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de klant"])},
      "selectGatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer router fabrikant"])},
      "selectOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een Omada controller"])},
      "selectPeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een Peplink locatie"])},
      "selectPeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer Peplink organisatie"])},
      "selectPeplinkTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer Peplink template file"])},
      "selectSwitchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer switch fabrikant"])},
      "selectUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een Unifi controller"])},
      "unknownCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
      "updateParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update parameters"])},
      "noInstallionLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is nog geen installatielogboek voor deze locatie."])},
      "addInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatielogboek toevoegen"])},
      "configReport": {
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratietype"])},
        "sim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim aanwezig"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internettype"])},
        "fritz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fritz!Box aanwezig"])},
        "vlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLAN's"])},
        "option138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option138"])},
        "option43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option43"])},
        "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi check"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasten portaal"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de gateway-leverancier"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de AP-leverancier"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de schakelaarleverancier"])},
        "devicesAdopted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten aangenomen"])},
        "sitesLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites gemaakt en gelinkt"])},
        "pppoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE"])}
      },
      "addConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratierapport toevoegen"])},
      "noConfigurationLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is nog geen configuratierapport voor deze locatie"])},
      "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn nog geen opmerkingen"])},
      "chooseGateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de fabrikant en controller voor de router"])},
      "selectVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer fabrikant"])},
      "chooseSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de fabrikant en controller voor de schakelaar"])},
      "chooseAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de fabrikant en controller voor het toegangspunt"])},
      "diyInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De klant zal zelf de installatie doen"])},
      "exportConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratierapport exporteren"])},
      "exportInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatielogboek exporteren"])},
      "exportLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport exporteren"])},
      "noWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er wordt geen leverancier gegeven voor toegangspunten. \nDraadloze netwerken kunnen niet worden geladen. \nGa alsjeblieft verder."])},
      "changeLocationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een diy-installatie"])},
      "changeLocationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van de locatie is anders dan de naam van de klant"])},
      "changeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik een locatienaam in plaats van de bedrijfsnaam. \nGebruik dit alleen als de locatienaam anders is dan de bedrijfsnaam."])},
      "restoreConfirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een locatie herstellen. \nDe locatie wordt weer zichtbaar voor de klant."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heractiveer locatie"])}
      },
      "subscription": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg de abonnements-ID van stripe toe om de details van het locatieabonnement te bekijken."])}
      },
      "vendors": {
        "modal": {
          "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Peplink"])},
          "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link TP-Link Omada"])},
          "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Unifi"])},
          "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Zerotier"])}
        },
        "peplink": {
          "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige gekoppelde ID"])},
          "newId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw geselecteerde ID"])}
        }
      },
      "selectPeplinkInstance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer Peplink-instantie"])},
      "choosePeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-exemplaar"])},
      "choosePeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink organisatie"])},
      "selectPeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer Peplink-groep"])},
      "choosePeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-groep"])},
      "selectOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer Omada-site"])},
      "chooseOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omada-site"])},
      "chooseVendorIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerkleveranciers"])},
      "ppsk": {
        "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK-profiel-ID"])},
        "currentSSID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID gekoppeld aan PPSK"])}
      },
      "selectPpskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer het PPSK-profiel"])},
      "ppskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK-profiel"])},
      "selectWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer SSID"])},
      "ppskSsid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID"])},
      "chooseZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een Zerotier-knooppunt"])},
      "selectZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies Zerotier-knooppunt"])},
      "caasHardware": {
        "modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-hardware"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren naar CSV"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen producten voor deze locatie"])}
      }
    },
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
    "newLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar voor configuratie"])},
    "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal vandaag"])},
    "switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
    "table": {
      "config": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste keer bewerkt"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
        "controllerVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllerversie"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geautoriseerd"])},
        "nodeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier-ID"])},
        "nodeIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knooppunt-IP"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])}
      },
      "customer": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
        "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeente"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op bedrijfsnaam"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Geen klanten gevonden</p><p>Wijzig de filters en probeer opnieuw.</p>"])}
      },
      "location": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point(s)"])},
        "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt"])},
        "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Er werden geen locaties gevonden.</p><p>Pas je zoekterm aan, misschien heb je dan meer geluk.</p>"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastenportaal"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op bedrijf of locatie"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
        "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
        "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratierapport maken"])},
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratie starten"])},
        "venue_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie naam"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
        "startInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatierapport maken"])},
        "qrImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-afbeelding"])},
        "qrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-label"])},
        "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print label"])},
        "viewConfigReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratierapport bekijken"])},
        "viewInstallReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk het installatierapport"])},
        "locationDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon locatie"])},
        "viewBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk facturatie"])},
        "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snoozen"])},
        "getCaasHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef CaaS-hardware weer"])}
      },
      "partners": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op partner"])}
      },
      "webhook": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "sales": {
        "hunterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
        "venueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatietype"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de locatie"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
        "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
        "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil offerte"])},
        "follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft opvolging nodig"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt bij"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
    "wlan": {
      "addRandomGeneratedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer een willekeurig wachtwoord"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé netwerk"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbaar netwerk"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "addModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een partner toe"])}
      },
      "addAccessModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang toevoegen"])}
      }
    },
    "partner": {
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner verwijderen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze partner wilt verwijderen?"])}
      }
    },
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])},
    "sales": {
      "totalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle hunters vandaag"])},
      "yourVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw bezoeken"])},
      "allTimeVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alltime bezoeken"])},
      "calc": {
        "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Recurring"])},
        "oneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal eenmalig"])},
        "amplrSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.setup"])},
        "hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])},
        "hardwareCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware CaaS MRR"])},
        "servicesMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensten CaaS MRR"])}
      },
      "configCalc": {
        "devices": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg de producten toe voor de offerte"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten"])}
        },
        "connections": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindingen"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de gewenste internetverbindingen"])}
        },
        "plan": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Plan"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies het gewenste SLA-plan voor de berekening"])}
        },
        "installCat": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de installatiecategorie op basis van de schattingen voor de installatie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatie categorie"])}
        },
        "amountVenues": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal locaties"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul het aantal locaties in"])}
        },
        "addDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg apparaten toe door te zoeken en te selecteren."])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekeningsdetails"])},
        "calculation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekening"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetailleerd overzicht van de berekening"])}
        }
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update succesvol opgeslagen"])}
      },
      "totalMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale MRR"])},
      "totalOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale eenmalige kosten"])}
    },
    "page": {
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales hunters"])}
      },
      "inventory": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaris"])}
      },
      "products": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-producten"])}
      },
      "allocated": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen CaaS"])}
      }
    },
    "statusModal": {
      "controllers": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opent een nieuw tabblad met de controllerinterface voor deze locatie"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloudcontrollers"])}
      }
    },
    "snoozed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snoozed"])},
    "customers": {
      "table": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      }
    },
    "payments": {
      "create": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (Serviceplan)"])},
        "placeholder": {
          "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies SLA"])},
          "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een internetverbinding"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999,99"])}
        },
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["internetverbinding"])},
        "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft failover"])},
        "hasPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft Peplink-router"])},
        "internetPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet nog niet geïnstalleerd"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak Stripe-prijs aan"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef het bedrag voor het abonnement op"])},
        "caasDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetecteerde apparaten"])}
      }
    },
    "inventory": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid"])},
      "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankoopprijs"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale waarde"])},
      "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
      "editProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg producten toe aan de inventaris"])}
      },
      "addInventory": {
        "productsToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product dat aan de inventaris wordt toegevoegd"])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan inventaris"])}
      },
      "addTnventory": {
        "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn nog geen producten om toe te voegen."])}
      },
      "addProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe productreferentie toevoegen"])}
      },
      "form": {
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productnaam van leverancier"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model (ex SG2008P)"])},
          "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN/SKU"])},
          "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankoopprijs"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkoopprijs"])},
          "caasPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-prijs"])},
          "configPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer de prijs, indien van toepassing"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG2008P"])},
          "amplrName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-productnaam"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een leverancier"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancier"])},
          "licencePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra maandelijkse licentieprijs"])}
        },
        "placeholder": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een producttype"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancier"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancier"])}
        },
        "name": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink BONE 5G"])}
        }
      },
      "deductInventory": {
        "productsToDeduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten die in mindering worden gebracht"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten aftrekken"])}
      },
      "deductProducts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten aftrekken van de voorraad"])}
      },
      "amplrProductSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer AMPLR-gerelateerd product"])},
      "amplrProductPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies artikel..."])},
      "createProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw voorraadproduct maken"])}
      },
      "updateProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorraadproduct bijwerken"])}
      },
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw voorraadproduct maken"])},
      "product": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-product maken"])}
      },
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestand"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "productModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productmodel / beschrijving"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
      "noModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen modellen"])},
      "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modellen"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product bijwerken"])}
    },
    "products": {
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies artikel"])}
    },
    "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier-knooppunten"])},
    "zerotierNodes": {
      "authorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemming geven"])},
      "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geautoriseerd"])}
    },
    "allocated": {
      "returnModal": {
        "listHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen producten"])},
        "lostHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verloren hardware (facturering vereist)"])},
        "archiveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archieflocatie"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-hardware retourneren"])}
      },
      "buttons": {
        "lostBroken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verloren/gebroken"])}
      },
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegewezen producten gevonden, selecteer of wijzig locatie."])},
      "nothingToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen producten gemarkeerd als verloren of kapot."])}
    },
    "amplrProduct": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een amplr-product"])}
    },
    "suppliers": {
      "selectSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een leverancier"])}
    },
    "portalYesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal gisteren"])}
  },
  "auth": {
    "login": {
      "alreadymember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heb je al een account?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
      "forgotPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom terug!"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw inloggegevens in om toegang te krijgen tot uw account"])},
      "logginIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen..."])},
      "titleMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedemorgen!"])},
      "titleAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedemiddag!"])},
      "titleEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedeavond!"])},
      "body2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw 2FA-code in om toegang te krijgen tot amplr.cloud"])},
      "title2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifieer je account"])}
    },
    "recover": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten? Herstel je wachtwoord door een resetlink."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur een link"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel je wachtwoord"])}
    },
    "register": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak je account aan voor amplr.cloud."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies je taal"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je werd succesvol geregistreerd"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer"])}
    },
    "reset": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een nieuw wachtwoord om in te loggen op amplr.cloud"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaar wachtwoord"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig je wachtwoord"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer uw connectiviteit"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.cloud is voor jou ontworpen. \nWe willen dat jij de controle over je internet en wifi overneemt. \nKrijg inzichten, wijzig instellingen, maak prachtige gastportalen en nog veel meer."])}
  },
  "button": {
    "admin": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant toevoegen"])},
      "addConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratie toevoegen"])},
      "addCustomersToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg bedrijf toe"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie toevoegen"])},
      "addLocationsCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf locatie toevoegen"])},
      "finishInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooi"])},
      "inviteUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodig gebruiker(s) uit"])},
      "printPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print label"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaand bedrijf toevoegen"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaande locatie toevoegen"])},
      "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start configuratie"])},
      "addLocationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatierapport toevoegen"])},
      "locationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie rapport"])},
      "addPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner toevoegen"])},
      "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang toevoegen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
      "sales": {
        "addVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezoek toevoegen"])}
      },
      "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg controller toe"])},
      "inventory": {
        "addQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg producten toe"])},
        "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw product maken"])},
        "deductQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten aftrekken"])}
      },
      "allocated": {
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-hardware retourneren"])}
      }
    },
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "deselectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselecteer alles"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontgrendel"])},
    "facebookLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Facebook"])},
    "googleLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Google"])},
    "linkedinLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met LinkedIn"])},
    "location": {
      "addDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg systeemgroep toe"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel een betaalmethode in"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende stap"])},
    "payments": {
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open de betaalpagina"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak link"])}
    },
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige stap"])},
    "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Label"])},
    "printQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print QR"])},
    "rebootNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart netwerk"])},
    "rebootNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart nu"])},
    "rebooting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart is bezig"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicatie opnieuw laden"])},
    "removeWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder webhook"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer alles"])},
    "showWebhookLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon log"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk"])},
    "webhookResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur webhook opnieuw"])},
    "webhookSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen..."])},
    "webhookTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur test"])},
    "showErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk de problemen"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceren"])},
    "createTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurder(s) aanmaken"])},
    "addMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een andere toevoegen"])},
    "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassen"])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer 2FA"])},
    "disableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel 2FA uit"])},
    "2fa": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code valideren"])},
      "validating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA-code valideren..."])}
    },
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer"])},
    "tenants": {
      "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurder(s) toevoegen"])}
    },
    "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg commentaar toe"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "scheduleDeactivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveringsdatum opslaan"])},
    "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu deactiveren!"])},
    "payOverDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achterstallige facturen betalen"])},
    "previewPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview gastportaal"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw versturen"])},
    "reloadList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herladen"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon details"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel ons"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heractiveren"])},
    "showCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon klant"])},
    "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon stripe-account"])},
    "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant bewerken"])},
    "removeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde groep verwijderen"])},
    "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement maken"])},
    "createInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur maken"])},
    "showLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon locatie"])},
    "salesAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog een bezoek toevoegen"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezoeken exporteren"])},
    "startAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw beginnen"])},
    "sales": {
      "configcalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratieberekening"])},
      "editVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezoek bewerken"])},
      "saveNewConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla nieuwe berekening op"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
    "impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe na"])},
    "stopImpersonating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop de impostermodus"])},
    "checkConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test verbinding"])},
    "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg controller toe"])},
    "editController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk controller"])},
    "portalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga terug"])},
    "editClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaat bewerken"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])},
    "newRefferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog een referral toevoegen"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vernieuwen"])},
    "createNewPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een ander"])},
    "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontkoppelen"])},
    "editLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link bewerken"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppeling"])},
    "addPpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link PPSK"])},
    "deletePpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK verwijderen"])},
    "printZerotierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier-label afdrukken"])},
    "releaseClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten bewerken"])},
    "viewGuestData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk gastgegevens"])},
    "openLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in streep"])},
    "createNewReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe DHCP-reservering"])},
    "createReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe DHCP-reservering aan"])}
  },
  "client": {
    "amountDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])},
    "config": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In afwachting"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])}
    },
    "dropdown": {
      "filterAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig"])},
    "filter": {
      "allNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle netwerken"])}
    },
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasten"])},
    "infoIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client IP"])},
    "infoName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client naam"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client informatie"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties"])},
      "clientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostnaam"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrikant"])},
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaal"])}
    },
    "wired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedraad"])},
    "wireless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draadloos"])},
    "updateFixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer een vast/statisch IP voor deze client"])},
    "dhcpInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het instellen van een vast/statisch IP-adres voor een apparaat zorgt ervoor dat het apparaat altijd hetzelfde IP-adres krijgt. Als u het adres wijzigt, moet dat apparaat opnieuw worden opgestart of moet u opnieuw verbinding maken met het juiste draadloze netwerk."])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je dient het apparaat opnieuw op te starten of je dient opnieuw verbinding te maken met het juiste draadloze netwerk om de wijzigingen te kunnen zien."])},
    "connectedWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je toestel is momenteel verbonden met ", _interpolate(_named("wlan")), ", verbind het toestel met ", _interpolate(_named("wlanToConnect"))])},
    "clientErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige apparaten hebben je aandacht nodig"])},
    "errorWlan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toestel <strong>", _interpolate(_named("device")), "</strong> is momenteel verbonden met <strong>", _interpolate(_named("old")), ". Verbind het met <strong>", _interpolate(_named("new")), "</strong></strong>"])},
    "errorIp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toestel <strong>", _interpolate(_named("device")), "</strong> heeft nieuwe instellingen, je dient het te herstarten."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aandacht nodig"])},
    "restartNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft wijzigingen aangebracht in deze client. \nStart het opnieuw op voordat u verder kunt gaan."])},
    "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vast IP"])},
    "hasStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit apparaat heeft een vast IP geconfigureerd."])},
    "informationStatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wees voorzichtig bij het wijzigen van instellingen. \nHet wijzigen van een IP/netwerk kan een grote impact hebben op uw netwerk. \nWij zijn nooit verantwoordelijk voor fouten als gevolg van verkeerde configuratie."])},
    "staticIpNotMatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het huidige IP-adres komt niet overeen met het vaste IP-adres. \nStart het apparaat opnieuw op of maak opnieuw verbinding met wifi"])},
    "releaseReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geconfigureerde apparaten"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geconfigureerde apparaten"])},
    "allReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle geconfigureerde clients met een vast IP-adres"])},
    "createReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een DHCP-reservering"])},
    "reservation": {
      "invalidMac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het opgegeven MAC-adres is ongeldig."])}
    },
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk alle geconfigureerde clients op het netwerk. \nGeconfigureerde clients hebben een DHCP-reservering."])},
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet verbonden"])}
  },
  "command": {
    "reboot": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart commando gegeven, herstart is bezig..."])}
    }
  },
  "cookiepopup": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Door op \"Alle cookies accepteren\" te klikken, gaat u akkoord met het opslaan van cookies op uw apparaat om de navigatie op de site te verbeteren, het gebruik van de site te analyseren en onze marketinginspanningen te ondersteunen. Bekijk ons ", _interpolate(_list(0)), " voor meer informatie."])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiger"])},
    "modal": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer alle cookies"])},
      "centrum": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer u websites bezoekt, kunnen zij gegevens in uw browser opslaan of opvragen. Deze opslag is vaak noodzakelijk voor de basisfunctionaliteit van de website. De opslag kan worden gebruikt voor marketing, analyse en personalisatie van de site, zoals het opslaan van uw voorkeuren. Privacy is belangrijk voor ons, dus u hebt de mogelijkheid om bepaalde soorten opslag uit te schakelen die niet noodzakelijk zijn voor de basiswerking van de website. Het blokkeren van categorieën kan uw ervaring op de website beïnvloeden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Voorkeur Centrum"])}
      },
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiger alle cookies"])},
      "preferences": {
        "analytics": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze items helpen de websitebeheerder te begrijpen hoe zijn website presteert, hoe bezoekers met de site omgaan en of er technische problemen zijn. Dit opslagtype verzamelt gewoonlijk geen informatie waarmee een bezoeker kan worden geïdentificeerd."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
        },
        "default": {
          "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd actief"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze items zijn nodig om de basisfunctionaliteit van de website mogelijk te maken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noodzakelijk"])}
        },
        "marketing": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze items worden gebruikt om advertenties te leveren die relevanter zijn voor u en uw interesses. Ze kunnen ook worden gebruikt om het aantal keren dat u een advertentie ziet te beperken en de doeltreffendheid van reclamecampagnes te meten. Advertentienetwerken plaatsen ze meestal met toestemming van de websitebeheerder."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
        },
        "personalisation": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze elementen stellen de website in staat om keuzes die u maakt te onthouden (zoals uw gebruikersnaam, taal of de regio waarin u zich bevindt) en verbeterde, persoonlijkere functies aan te bieden. Een website kan u bijvoorbeeld voorzien van lokale weer- of verkeersinformatie door informatie over uw huidige locatie op te slaan."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisatie"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemmingsvoorkeuren per categorie beheren"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig mijn voorkeuren en sluit"])}
    },
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkeuren"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])}
  },
  "customer": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bedrijfsadres"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])}
    },
    "invoices": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van je facturen"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download factuur"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Er werden geen facturen gevonden.</p>"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalen"])},
      "table": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuurnummer"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur datum"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuurstatus"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur bedrag"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])}
    },
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van je betalingsmethoden, lopende abonnementen en facturen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer je betalingen"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van je bedrijfsinfo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
    "logo": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload uw logo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw logo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload uw logo zodat we sommige pagina's kunnen voorzien van uw logo"])}
    },
    "info": {
      "lastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belangrijke waarschuwing"])},
      "accountLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft openstaande facturen waarvan de vervaldatum al is verstreken. \nIndien deze onbetaald blijven zijn wij genoodzaakt juridische stappen te ondernemen. \nAls je naar aanleiding van dit bericht nog vragen hebt, neem dan contact met ons op 32 3 375 60 70."])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het plan van de klant"])}
    },
    "stripe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulpmiddelen voor billing"])},
      "paymentPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer de betalingsportaal-URL voor de klant"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open een klantaccount in Stripe"])}
    },
    "create": {
      "header": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresgegevens"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsgegevens"])},
        "invoicingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturatiegegevens"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardplan"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aanmaken"])}
      },
      "accountWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de optie Account aanmaken uitschakelt, wordt er geen account aangemaakt. \nMaar ook niet gekoppeld aan een bestaande gebruiker/account. \nAls u deze aan een bestaande gebruiker wilt koppelen, staat u dit toe en vult u het e-mailadres van de geregistreerde gebruiker in."])}
    },
    "paymentMethod": {
      "toggleMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actieve betaalmethode (controleer Stripe)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalmethode"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel de vlag voor de betaalmethode handmatig in."])}
    }
  },
  "dashboard": {
    "details": {
      "downloadQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR code"])}
    },
    "devices": {
      "noap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen draadloos netwerk gevonden"])},
      "nogateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gateway gevonden"])},
      "noswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen switch gevonden"])}
    },
    "invoices": {
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle facturen zijn betaald."])}
    },
    "location": {
      "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog niet geïnstalleerd"])}
    },
    "locations": {
      "allLocationsInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle netwerken op alle locaties zijn geïnstalleerd."])},
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle netwerken op alle locaties zijn in orde."])}
    },
    "notification": {
      "allSystemsDown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je amplr-netwerk is offline. \nHeeft u elektriciteit? \nJa? \nBel <span class=\"has-text-weight-bold\">", _interpolate(_named("provider")), "</span> op <a href=\"tel:", _interpolate(_named("phone")), "\">", _interpolate(_named("phone")), "</a>!"])},
      "allSystemsRunning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw amplr-netwerk is volledig operationeel. \nJe internetverbinding wordt verzorgd door <span class=\"has-text-weight-bold\">", _interpolate(_named("providerName")), "</span>."])},
      "degradedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een deel van uw netwerk is niet operationeel."])},
      "failOverActive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failover is actief. Dat wil zeggen dat enkel kassaverkeer, betalingen en Spotify kunnen gebruikt worden. Eventuele gastennetwerken of andere netwerken zullen tijdelijk niet beschikbaar zijn. Jouw verbinding is op basis van SIM en is verbonden met het ", _interpolate(_named("carrier_name")), " netwerk."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We halen de informatie op van deze locatie."])},
      "readyForInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze locatie is nog niet klaar voor gebruik. We wachten tot de installatie voltooid is."])},
      "noHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We konden geen hardware vinden die op uw systeem is aangesloten."])},
      "powerOutage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"has-text-weight-bold\">", _interpolate(_named("source")), "</span> heeft een elektriciteitsstoring gemeld in jouw regio. \nDe storing begon op <span class=\"has-text-weight-bold\">", _interpolate(_named("startDate")), "</span> en zou moeten duren tot <span class=\"has-text-weight-bold\">", _interpolate(_named("endDate")), "</span> \n."])}
    },
    "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een open netwerk voor gasten."])},
    "titles": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draadloos"])},
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbonden clients"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeemgroepen"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbetaalde facturen"])},
      "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties die aandacht vragen"])},
      "locationsReadyForInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties klaar voor installatie"])},
      "privateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiligde netwerken"])},
      "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publieke netwerken"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
      "wan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])}
    },
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rij toevoegen"])},
    "wlan": {
      "ppsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit netwerk maakt gebruik van PPSK (Private Pre-shared Key). Wachtwoorden zijn per gebruiker/bedrijf in te stellen in de huurders tab."])}
    },
    "financials": {
      "fromTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervolledigen"])},
      "yearToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar tot op heden"])},
      "currentMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze maand"])},
      "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige maand"])},
      "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRR"])},
      "avgDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per dag"])}
    },
    "tooltips": {
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeemgroepen zijn een verzameling netwerkapparaten zoals computers, luidsprekers, alarmen, camera's, enzovoort. \nMaak aangepaste groepen om de verbinding te controleren."])}
    },
    "topology": {
      "gateway": {
        "fixedLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaste lijn"])},
        "cellular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4G/5G"])},
        "wanIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbaar IP-adres"])},
        "virtualWan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtueel WAN"])}
      }
    },
    "wifi": {
      "privateNetworks": {
        "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak netwerk verborgen"])},
        "tooltip": {
          "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een netwerk verborgen maakt, wordt het wifi-netwerk niet gezien tijdens het scannen. \nU kunt verbinding maken met een verborgen netwerk door de netwerkgegevens handmatig in te voeren"])},
          "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een zichtbaar netwerk kan door apparaten worden gezien tijdens het scannen naar wifi-netwerken. \nAls u dit netwerk verborgen wilt maken, schakelt u de uitzending uit. \nAls u verbinding wilt maken met een verborgen netwerk, moet u de wifi-naam en het wachtwoord handmatig invoeren."])}
        },
        "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak dit netwerk zichtbaar (uitzending)"])}
      }
    },
    "privateNetworks": {
      "isHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verborgen netwerk"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi-instellingen bewerken"])},
      "label": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi-naam"])}
      }
    }
  },
  "datepicker": {
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze maand"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit jaar"])}
  },
  "error": {
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt geen toegang tot deze content."])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gevonden"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er gebeurde een fout op de server."])},
    "apiThrottled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je recent al een resetlink aangevraagd."])},
    "auth": {
      "invalidResetToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het reset token is niet correct."])},
      "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De paswoorden komen niet overeen."])},
      "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit account bestaat niet."])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het paswoord is verkeerd."])}
    },
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaat is niet aangesloten"])},
    "file": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bestand is te groot om te uploaden. De maximaal ondersteunde bestandsgrootte is 500 kb."])}
    },
    "generalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er liep iets mis."])},
    "image": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De afbeelding is te groot. De maximale grootte is 5 mb."])}
    },
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailvalidatie is niet gelukt. Geef een geldig e-mailadres in."])},
    "invalidPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig public id"])},
    "messageLoadingLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het laden duurt langer dan normaal..."])},
    "noClientsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen clients gevonden."])},
    "noDeviceGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen systeemgroepen gevonden"])},
    "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toestellen gevonden."])},
    "noInvitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen uitnodigingen gevonden"])},
    "noPrivateNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen private netwerken gevonden."])},
    "noPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps, de URL is niet correct."])},
    "noPublicNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen publieke netwerken gevonden."])},
    "noSitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen locaties gevonden"])},
    "noUserDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen data gevonden."])},
    "noUsersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers gevonden"])},
    "portal": {
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het passwoord is niet correct."])}
    },
    "portalClient": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We vonden je e-mailadres niet terug. Dit betekent dat we geen data van jou hebben opgeslagen."])}
    },
    "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je sessie is verstreken en bent uitgelogd. Je moet opnieuw inloggen."])},
    "sitesNnone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sites gevonden"])},
    "userExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigingen niet verstuurd.<br/>Volgende gebruikers kunnen niet uitgenodigd worden. Verwijder ze van de lijst en probeer opnieuw."])},
    "value": {
      "isEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een waarde in te geven"])},
      "notDigits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De waarde is niet geldig"])},
      "notInRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De waard ligt niet in het bereik"])}
    },
    "vat": {
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit BTW nummer bestaat al."])},
      "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit BTW nummer bestaat al."])},
      "stripeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe error: onjuist EU-btw-nummer opgegeven. \nGebruik een wettelijk BTW-nummer."])}
    },
    "noPermissionsSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve voor elke kolom minstens 1 permissie te selecteren."])},
    "noTenantsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen huurders gevonden"])},
    "tenants": {
      "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De CSV was niet in het juiste formaat"])}
    },
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, je inlogpoging is mislukt. Controleer uw gebruikersnaam en wachtwoord en probeer het opnieuw."])},
    "2fInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code is ongeldig."])},
    "config": {
      "configId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een configuratie"])},
      "choosePeplinkSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul het Peplink-serienummer in"])},
      "chooseController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een controller"])},
      "chooseVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een leverancier"])},
      "wlansName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul alle namen in"])},
      "plan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op basis van het aantal apparaten moet u het volgende abonnement selecteren: ", _interpolate(_named("plan")), " of hoger"])}
    },
    "partners": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen partners gevonden"])}
    },
    "noAccessFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang gevonden"])},
    "noLocationsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen locaties gevonden"])},
    "noGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groepen gevonden"])},
    "no": {
      "link": {
        "access": {
          "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We konden u niet registreren omdat de gebruikte link niet meer geldig is."])}
        }
      }
    },
    "invalidSubscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige abonnements-ID."])},
    "subscriptionNotLinkedToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De opgegeven abonnements-ID is niet gekoppeld aan de klant die aan deze locatie is gekoppeld."])}
  },
  "errorPage": {
    "404": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... deze pagina bestaat niet meer of is verplaatst."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gevonden"])}
    },
    "maintenance": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze excuses voor het ongemak. We zijn snel weer online."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De applicatie is momenteel offline voor onderhoud."])}
    }
  },
  "form": {
    "field": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
      "configName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config name"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "customerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
      "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam systeemgroep"])},
      "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download snelheid"])},
      "downloadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download snelheid van de failover"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "freeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adres"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "nameInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de installateur"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig wachtwoord"])},
      "passwordNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
      "peplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink org"])},
      "peplinkSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink serial"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider"])},
      "providerid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider ID"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek"])},
      "searchNetworkDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een netwerkapparaat"])},
      "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload snelheid"])},
      "uploadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload snelheid van de failover"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW-nummer"])},
      "verifyBeforeDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal bovenstaand label"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin datum/tijd"])},
      "startDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies datum/tijd"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eind datum/tijd"])},
      "endDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies datum/tijd"])},
      "locationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie type"])},
      "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim-nummer"])},
      "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "placeholder": {
        "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00000000000000000000"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een internettype"])},
        "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.0.0.0"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer leverancier"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])},
        "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE-gebruikersnaam"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*********"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestand"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "amplr.be"])},
        "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be"])},
        "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een partner"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokeren"])},
        "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9160"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieptestraat 2"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE0768.611.469"])},
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["127.0.0.1"])},
        "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplr"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplr"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John"])},
        "searchProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek naar producten"])},
        "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek locatie"])},
        "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een vennootschapsvorm"])},
        "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef de abonnements-ID op: sub_xxxxxxxx"])},
        "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef de ID van het abonnementsitem op: si_xxxxx"])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn eerste cloudcontroller"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3247777777"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer/ruimte"])},
        "mailchimpAudiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw Mailchimp-doelgroep"])},
        "ipSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["199"])}
      },
      "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller IP"])},
      "amountGateways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid gateways"])},
      "amountAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid AP"])},
      "amountSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoeveelheid switch"])},
      "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE-gebruikersnaam"])},
      "pppoePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE-wachtwoord"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie naam"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een branche"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruimtes"])},
      "subnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle netwerken"])},
      "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennootschapvorm"])},
      "placholder": {
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])}
      },
      "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe-abonnements-ID"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectie type"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft failover"])},
      "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsitem-ID"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer merk van de controller"])},
      "tooltip": {
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef de volledige controller-URL met de poort. \nBijvoorbeeld: https://uwdomein.com:8443 of https://193.12.123.12:8443."])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een alias aan uw controller voor interne referentie. \nAls u dit veld leeg laat, wordt er een willekeurige tekenreeks gegenereerd."])}
      },
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
      "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller-URL"])},
      "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller-alias"])},
      "replyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail antwoord naar adres"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer/ruimte"])},
      "providerSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevredenheid van de aanbieder"])},
      "providerSatisfactionFreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom is de klant tevreden"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een reden"])},
      "selectFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer vervolg"])}
    },
    "fileInput": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder bestand"])}
    },
    "select": {
      "selectDeviceGroupType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeemgroep type"])}
    },
    "label": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot welk netwerk behoort dit apparaat?"])},
      "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een alias aan het apparaat"])},
      "useStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik een statisch/vast IP-adres"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen"])},
      "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onthoud mij"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie heeft een failover-verbinding"])},
      "connectionTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectie type"])},
      "uniformBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel specifiek item uit abonnement"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de locatie"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactpersoon"])},
      "chooseProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer huidige internetprovider"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassasysteem"])},
      "canUseFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maakt gebruik van bezorg-/clouddiensten/..."])},
      "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft terras"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil een offerte"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een grootte"])},
      "walkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit was een walk in"])},
      "notInterested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geïnteresseerd"])},
      "config": {
        "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover-oplossing"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "isPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-router"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "amountVenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal locaties"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Plan"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatie categorie"])},
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["internetverbinding"])},
        "extraPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag speciale Peplink-licentie (bruto)"])},
        "hasSpecialPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft speciale Peplink-licentie"])},
        "maxOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaal bedrag voor eenmalige kosten"])},
        "fixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vast IP-adres"])}
      },
      "variantCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is Connectivity-as-as-Service"])},
      "visit": {
        "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geïnteresseerd"])},
        "marketingOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemming voor e-mails/marketing"])},
        "knowsAmplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kent amplr al"])}
      },
      "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifipunten"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander"])},
      "needsFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft opvolging nodig"])},
      "contactPersonPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer contactpersoon"])},
      "contactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail contactpersoon"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de locatie"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestelnaam"])},
      "selfOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft een kiosk"])},
      "takeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft afhaal/bezorging"])},
      "satisfactionScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevredenheidsgraad"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden waarom ongelukkig"])},
      "followUpMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opvolgactie"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is de rol van de woordvoerder"])},
      "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van knooppunt"])},
      "nodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knooppuntbeschrijving"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC-adres"])}
    },
    "placeholder": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer netwerk"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een verbindingstype"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een kassasysteem"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootte van de locatie"])},
      "config": {
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een verbindingstype"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een SLA-abonnement"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieer de installatiecategorie"])}
      },
      "sales": {
        "loadExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laad een bestaande berekening"])}
      },
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een rol"])}
    },
    "noResults ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden. \nProbeer het nog eens."])}
  },
  "general": {
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "connectionRestored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben terug een actieve internetverbinding gedetecteerd op jouw toestel. Je kan verder gebruik maken van amplr.cloud! Druk op vernieuwen of wacht tot het venster verdwijnt."])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt geen verbinding met het internet. Zorg dat je een actieve internetverbinding hebt om amplr.cloud te gebruiken."])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "group": {
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt hier uw partners toevoegen of verwijderen"])}
    }
  },
  "groupProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het adres van de groep"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van je groep details"])}
    }
  },
  "history": {
    "table": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "noFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen failover"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Er werd geen data gevonden.</p><p>Pas je zoekterm aan, misschien heb je dan meer geluk.</p>"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])}
    }
  },
  "info": {
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachten op verzoeken..."])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveilig uw account met multifactorauthenticatie. \nGebruik de Google Authenticator-app of uw favoriete wachtwoordbeheerder."])},
    "twoFactorActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je account is beveiligd met multifactorauthenticatie."])},
    "loadingPeplinkGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even geduld terwijl we alle Peplink-groepen laden die gerelateerd zijn aan de geselecteerde organisatie."])}
  },
  "location": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het adres van de locatie"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])}
    },
    "deviceGroups": {
      "addDeviceGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe systeemgroep aan"])},
      "addFirstDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak je eerste systeemgroep"])},
      "addMoreDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg meer toestellen toe"])},
      "connectedClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actieve toestellen op het netwerk"])},
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg apparaten toe aan je groep"])},
      "devicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies minstens 1 apparaat om toe te voegen aan je groep"])},
      "editModal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kunt u de naam en het type van uw systeemgroep wijzigen"])},
        "deviceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten toevoegen aan of verwijderen uit uw groep"])},
        "deviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten in systeemgroep aanpassen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep naam"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeem groep info"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep type"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een naam aan je groep"])},
      "newDeviceGroup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cool! Systeemgroep (", _interpolate(_named("name")), ") aangemaakt, je kan nu kijken of de toestellen verbonden zijn"])},
      "newDeviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestellen in systeemgroep"])},
      "notification": {
        "deviceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe systeemgroep aangemaakt"])},
        "deviceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeemgroep aangepast"])}
      },
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsinfo toevoegen"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten toevoegen"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een type voor uw groep"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze apparaatgroep wilt verwijderen?"])}
    },
    "installation": {
      "bridge": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is er een bridge aanwezig?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een keuze"])}
      },
      "failover": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is er een failover aanwezig?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een keuze"])}
      },
      "guest_wifi": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is het gasten wifi in orde?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een keuze"])}
      },
      "images": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laad enkele afbeeldingen op van de installatie"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload afbeeldingen"])}
      },
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
    "updateWlan": {
      "form": {
        "fields": {
          "verifyBeforeSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig om aan te passen"])}
        }
      },
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je de instellingen van dit wifi-netwerk wijzigt, verliezen alle reeds verbonden apparaten hun verbinding en moeten ze opnieuw worden verbonden met de nieuwe instellingen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi-instellingen wijzigen voor:"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u de instellingen toch wijzigen? \nBevestig door de wifi-netwerknaam in te voeren."])}
    },
    "connection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de aansluiting voor de locatie"])}
    },
    "create": {
      "header": {
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie naam"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindingen"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturatie"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatietype"])}
      },
      "customerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik klantadres als vestigingsadres"])}
    },
    "createSubscription": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement maken"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuw stripe-abonnement aan voor deze locatie. \nU wordt doorgestuurd naar stripe."])}
    },
    "createInvoice": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een factuur aan voor eenmalige kosten of interventies."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur maken"])}
    },
    "billing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsgegevens"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over het locatieabonnement vindt u hier."])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensten"])},
      "serviceBilledButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service wordt gefactureerd, maar is niet actief op locatie"])},
      "serviceNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service is actief op locatie, maar wordt niet gefactureerd."])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Plan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel het serviceabonnement voor deze locatie in."])}
    },
    "config": {
      "unifiOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is een Ubiquiti OS-locatie"])}
    },
    "topology": {
      "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub"])},
      "tooltip": {
        "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub-verbindingen die beschikbaar zijn tussen deze locatie en de hub."])}
      }
    }
  },
  "locationProfile": {
    "commands": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer functies uit op jouw netwerk"])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart alle netwerk toestellen"])},
      "rebootNetwork": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer je alle netwerk toestellen herstart is er tijdelijk geen internet op deze locatie. Het opnieuw opstarten van deze toestellen kan enkele minuten duren. Ben je zeker?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstart alle netwerk toestellen"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht centrum"])}
    },
    "hours": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hier je openingsuren in"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openingsuren"])}
    },
    "info": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra gegevens over jouw locatie"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
    },
    "qr": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer een publieke QR-code"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een publieke QR code stelt je medewerkers in staat snel de status van het netwerk op te halen. Verwerk de QR code in jouw procedures of plaats ze op de locatie."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support QR"])}
    },
    "service": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op basis van je serviceplan kan je gebruik maken van volgende diensten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbare services"])}
    }
  },
  "locations": {
    "table": {
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Er werden geen locaties gevonden.</p><p>Pas je zoekterm aan, misschien heb je dan meer geluk.</p>"])},
      "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR-code"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek"])},
      "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
      "sitePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van je betaalmethoden, lopende abonnementen en facturen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer uw betalingen"])}
    },
    "installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geïnstalleerd"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])}
  },
  "mainNav": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "admin": {
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingen"])},
      "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaris"])},
      "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier"])}
    },
    "app": {
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasten portaal"])}
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log uit"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])}
    },
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abo"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verkoop"])},
    "refferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijzingsbonus"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorverwijzingsbonus"])}
  },
  "modalAdminAddCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf aanmaken"])},
  "mydata": {
    "download": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien je je gegevens wenst te downloaden, vul dan onderaan je e-mailadres in."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens downloaden"])}
    },
    "optout": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien je wenst dat we je gegevens verwijderen, vul dan onderaan je e-mailadres in."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens verwijderen"])}
    }
  },
  "nav": {
    "account": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    },
    "admin": {
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
      "sales": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
        "createVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezoek maken"])},
        "configCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratie calculator"])}
      },
      "inventory": {
        "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaris"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-producten"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logboeken"])},
        "allocatedCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen CaaS"])}
      }
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log uit"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    },
    "customer": {
      "b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B"])},
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2C"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
      "titleB2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuspagina"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])}
    },
    "dashboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "deviceGroups": {
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie"])}
    },
    "group": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])}
    },
    "locations": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparatuur"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemen"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschiedenis"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
      "topology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
      "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendors"])},
      "installationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatie rapport"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuspagina"])},
      "tenantsManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurders"])},
      "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratie rapport"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasten portaal"])}
    },
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "users": {
      "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigingen"])},
      "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
    },
    "partner": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "invites": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigingen"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang"])}
    }
  },
  "noAccess": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, je hebt geen toegang tot deze pagina."])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, je hebt geen toegang tot deze pagina. Upgrade je plan om toegang te krijgen tot deze pagina."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])},
    "titleNoPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang. Gelieve je wachtwoord in te geven."])}
  },
  "noPlan": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om toegang te krijgen tot deze pagina dien je over een hoger plan te beschikken. Contacteer ons voor de mogelijkheden."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade je plan"])}
  },
  "notPayed": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account is tijdelijk uitgeschakeld. \nNeem contact op met de beheerder van uw bedrijf om de betalingsgegevens in te vullen."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])}
  },
  "notification": {
    "customer": {
      "noPaymentMethod": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er is momenteel geen betaalmethode ingesteld voor ", _interpolate(_named("customerName")), ". Vermijd automatische afsluiting van je internet en services. "])},
      "paymentMethodSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt een actieve betaalmethode. Indien je deze graag wijzigt, ga naar de betaalpagina."])}
    },
    "demoMode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("name")), ", je bekijkt amplr.cloud in demomodus. \nGeen zorgen, er zijn geen functies verborgen! \nAls u vragen heeft over het platform. \nNeem contact op met support", "@", "amplr.be!"])},
    "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snooze-instelling bijgewerkt"])},
    "imposter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je imiteert momenteel <strong>", _interpolate(_named("name")), "</strong>. \nAls je de impostermodus wilt verlaten, druk dan op de stopknop!"])},
    "referral": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor het verwijzen! \nWij nemen contact op met uw lead en houden u op de hoogte. \nJe kunt nog een verwijzing toevoegen als je wilt!"])}
    }
  },
  "openingHours": {
    "createBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak openingsuren aan voor deze locatie. Je kan ze gebruiken om je gastenportaal offline te zetten als de zaak gesloten is."])},
    "error": {
      "timePerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve per geopende dag een openings- en sluitingsuur in te vullen."])},
      "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een openings- en sluitingsuur in te vullen."])}
    },
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke dag hetzelfde schema"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vr"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn nog geen openingsuren ingesteld"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo"])}
  },
  "pagination": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])}
  },
  "payments": {
    "methods": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een link aan voor het opzetten van een SEPA/creditkaart methode, zodat we klanten recurrent kunnen chargen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalmethodes"])}
    },
    "caasPrice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een CaaS-prijs"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe priceId in stripe voor een locatie met connectivity-as-a-service."])}
    },
    "stepList": {
      "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open deze Stripe-link om een ​​abonnement aan te maken"])},
      "pasteProductId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak deze priceId in het productzoekveld"])},
      "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg metadata toe, gebruik 'siteId' als sleutel en plak de volgende locationId in waarde"])},
      "invoiceMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak de volgende waarde in het factuurnotaveld (onder geavanceerd)"])},
      "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk op de knop 'Abonnement aanmaken' om te voltooien."])}
    },
    "createInvoice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe factuur"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start vanaf hier het aanmaken van facturen, zodat de factuur aan de locatie kan worden gekoppeld."])}
    }
  },
  "permissions": {
    "customer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])}
    },
    "customer_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om bedrijf gegevens te bekijken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf bekijken"])}
    },
    "customer_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om bedrijf gegevens te wijzigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf wijzigen"])}
    },
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])}
    },
    "group_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om groep gegevens te bekijken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep bekijken"])}
    },
    "group_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om groep gegevens te wijzigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep wijzigen"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])}
    },
    "invoices_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om factuur gegevens te bekijken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen bekijken"])}
    },
    "invoices_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om factuur gegevens te wijzigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen wijzigen"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])}
    },
    "location_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om locatie gegevens te bekijken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie bekijken"])}
    },
    "location_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om locatie gegevens te wijzigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie wijzigen"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal"])}
    },
    "portal_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om portaal gegevens te bekijken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal bekijken"])}
    },
    "portal_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om portaal gegevens te wijzigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal wijzigen"])}
    },
    "users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
    },
    "users_create": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om gebruikers toe te voegen aan bepaalde groepen, bedrijven of locaties."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers toevoegen"])}
    },
    "users_delete": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om gebruikers te verwijderen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers verwijderen"])}
    },
    "users_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om gebruiker gegevens te bekijken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers bekijken"])}
    },
    "users_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft de gebruiker rechten om gebruiker gegevens te wijzigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers wijzigen"])}
    },
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft geen rechten om acties uit te voeren."])}
  },
  "policy": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Door te verbinden met dit gastennetwerk, erkent u dat u deze overeenkomst hebt gelezen en begrepen en dat u ermee instemt eraan gebonden te zijn. Dit gastennetwerk wordt geleverd door de eigenaars en is volledig naar hun goeddunken. Uw toegang tot het netwerk kan worden geblokkeerd, opgeschort of beëindigd op elk gewenst moment om welke reden dan ook. U stemt ermee in het draadloze netwerk niet te gebruiken voor onwettige doeleinden en u neemt de volledige verantwoordelijkheid op u voor uw daden. Het draadloze netwerk wordt aangeboden 'zoals het is' zonder garanties van welke aard dan ook, expliciet of impliciet.</p><p>Om de verbinding van dit gastennetwerk tot stand te brengen wordt het mac-adres van de te verbinden client gebruikt en opgeslagen.</p><p>AMPLR BV kan in geen enkel geval aansprakelijk gesteld worden voor het gebruik van dit draadloze netwerk.</p><p>AMPLR BV kan gegevens bewaren indien deze gevraagd worden bij het aanmelden van op het netwerk. In geen geval worden deze gegevens gebruikt voor marketing doeleinden, tenzij de gebruiker toestemming geeft (opt-in).</p><p>Indien de gebruiker zijn gegevens wil inkijken/bewerken of verwijderen kan deze contact opnemen door te mailen naar dataprotection[at]amplr.be</p>"])},
    "optout": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien je je gegevens wenst te bekijken of te wissen uit onze database, klik dan op onderstaande knop."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn data"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens bekijken en verwijderen"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarden"])},
    "titleOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantoor"])}
  },
  "portal": {
    "body": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De openbare wifi is momenteel niet beschikbaar. Probeer het later opnieuw."])},
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We zijn momenteel gesloten."])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door op verbinden te klikken gaat u akkoord met onze"])},
      "policyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voorwaarden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a> biedt gratis, veilige en snelle wifi op deze locatie. \nDruk op verbinden en begin met surfen."])},
      "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectie aan het beveiligen"])},
      "trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+ locaties gebruiken <a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a>"])}
    },
    "client": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je e-mailadres in"])}
      },
      "emailOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn gegevens mogen gebruikt worden voor marketing doeleinden."])}
      },
      "firstname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je voornaam in"])}
      },
      "lastname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je achternaam in"])}
      },
      "phonenumber": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je telefoonnummer in"])}
      },
      "premiumWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Bij opt-in krijgt u snellere wifi)"])},
      "forceOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om gebruik te maken van de gratis wifi erken ik dat mijn gegevens gebruikt kunnen worden voor marketingdoeleinden."])}
      },
      "emailOptInPiggy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn gegevens kunnen worden gebruikt voor marketingdoeleinden en loyaliteit."])}
      }
    },
    "overview": {
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd verbonden gasten"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle aangesloten gasten vandaag"])},
      "todayOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing-opt-in vandaag"])}
    },
    "settings": {
      "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde instellingen"])},
      "analytics": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een Google Tag Manager of Facebook Pixel ID toe om tracking toe te voegen aan je portal"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
      },
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, je bent de algemene bedrijfsinstellingen aan het wijzigen"])},
      "data": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer hier welke data je van de gebruiker wil verzamelen."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk data"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data verzamelen"])}
      },
      "design": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de afbeeldingen, kleuren en tekst aan van je portaal."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer pagina"])}
      },
      "form": {
        "analyticsFacebook": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Pixel ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456789123456"])}
        },
        "analyticsGoogle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Tag Manager ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTM-ABC1234"])}
        },
        "analyticsPrivacyPolicy": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy URL"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be/privacy-policy"])}
        },
        "background": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtergrond afbeelding (jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestand"])}
        },
        "bgColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtergrondkleur"])}
        },
        "body": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de body tekst aan"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tekst voor de body"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer pagina"])},
        "buttonText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de button tekst aan"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tekst voor de button"])}
        },
        "client": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien niets aangevinkt, kan de gebruiker connecteren zonder data op te geven"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke data wil je van de gebruiker hebben?"])}
        },
        "color": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een kleur"])}
        },
        "customer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik bedrijfsinstellingen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt de bedrijfsinstellingen volgen, zodat u niet voor elke locatie een portal hoeft in te richten."])}
        },
        "disabled": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats portaal tijdelijk offline"])}
        },
        "disabledText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de tijdelijke offline tekst aan"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tekst voor wanneer het portaal tijdelijk offline is"])}
        },
        "duration": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdspanne (min)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bv. 30"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepaal hoe lang gasten verbonden kunnen blijven na authenticatie."])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo (svg, jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestand"])}
        },
        "mainColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdkleur"])}
        },
        "offlineText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de offline tekst aan"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tekst voor wanneer het portaal offline is"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paswoord"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een paswoord in."])}
        },
        "passwordToggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik een paswoord om in te loggen"])}
        },
        "schedule": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig openingsuren"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats portaal offline buiten openingsuren"])}
        },
        "secondaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accentkleur"])}
        },
        "secondaryTextColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accent tekstkleur"])}
        },
        "textColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekstkleur"])}
        },
        "toggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepersonaliseerd portaal"])}
        },
        "url": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landingspagina"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bv. https://www.amplr.be"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepaal waar gasten naartoe moeten na authenticatie. \nDit kan uw website of een andere landingspagina zijn."])}
        },
        "webhookEndpoint": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook URL"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://yourcustomwebhook.zapier.com"])}
        },
        "webhookSecret": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook shared secret"])}
        },
        "primaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primaire kleur"])}
        },
        "forceOptIn": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forceer aanmelding voor marketing"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door deze functie in te schakelen, moeten klanten die verbinding willen maken het vakje met betrekking tot marketingdoeleinden aanvinken."])}
        },
        "forceEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door gebruik te maken van gedwongen e-mailvalidatie worden alle e-mailadressen gevalideerd door een externe provider om te controleren of het gebruikte adres legitiem is."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel e-mailadresvalidatie in"])}
        },
        "strictEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikte validatie houdt in dat alleen adressen met een gezondheidsscore van 100 worden toegestaan. \nAls de score lager is maar nog steeds leverbaar, krijgt de gebruiker een foutmelding omdat strikte validatie is ingeschakeld."])}
        },
        "strictValidation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikte validatie afdwingen"])}
        }
      },
      "general": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas hier de algemene instellingen van je portaal aan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
      },
      "login": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies hier hoe je wenst dat een gebruiker zich inlogt op je publieke Wifi. Indien niets gekozen kan de gebruiker connecteren zonder authenticatie."])},
        "facebook": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je Facebook API key in"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je Facebook API secret key in"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook API key"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook API secret key"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon Facebook login button"])}
        },
        "google": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je Google API key in"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google API key"])},
          "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: Google login werkt niet op iOS captive portal"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon Google login button"])}
        },
        "linkedin": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je LinkedIn API key in"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je LinkedIn API secret key in"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn API key"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn API secret key"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon LinkedIn login button"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie"])}
      },
      "preview": {
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desktop"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile"])},
        "tablet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tablet"])}
      },
      "schedule": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal tijdelijk offline zetten of buiten de openingstijden offline zetten"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je wijzigingen werden opgeslagen."])},
      "tabs": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans"])},
        "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lay-out"])},
        "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duits"])}
      },
      "webhook": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak gebruik van webhooks om data te versturen naar een andere/eigen applicatie."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])}
      },
      "customPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op dit moment wordt een amplr-branded pagina gebruikt als gast-wifi-portaal. \nSchakel \"Gepersonaliseerd portaal\" in om uw branding te gebruiken."])},
      "integrations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integraties"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haal het maximale uit uw gastenwifi door gebruik te maken van onze integraties."])}
      }
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De instellingen werden opgeslaan."])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokkeer gebruiker"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestel"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adres"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mac adres"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Er werden geen gebruikers gevonden.</p><p>Pas de filters aan, misschien heb je dan meer geluk.</p>"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op e-mail of mac adres"])},
      "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deblokkeer gebruiker"])}
    },
    "integration": {
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactief"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "message": {
        "piggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit u aan bij het <b>loyaliteit</b> programma en ontvang onmiddellijk voordelen."])}
      }
    },
    "integrations": {
      "smartendrModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartendr"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze integratie te gebruiken, moet je een actief Smartendr-abonnement hebben."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel deze integratie in"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Smartendr"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel deze integratie in"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak de API-sleutel in uw Smartendr-account"])}
      },
      "piggyModal": {
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw API key in, deze vindt u in het Piggy dashboard"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Biggie"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piggy"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze integratie te gebruiken heb je een actief abonnement bij Piggy nodig."])}
      },
      "mailchimpModal": {
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp openen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze integraties te gebruiken, heb je een actief Mailchimp-abonnement nodig."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel deze integratie in"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel deze integratie in"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak uw API-sleutel, deze vindt u in het Mailchimp dashboard"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer uw publiek"])}
      }
    },
    "error": {
      "mailNotvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps! \nHet lijkt erop dat dat e-mailadres verstoppertje speelt. \nProbeer het opnieuw met een echte, alstublieft!"])}
    }
  },
  "scheduler": {
    "buttons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode toevoegen"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])}
    },
    "duration": {
      "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keer in totaal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot"])}
    },
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van"])},
    "repeat": {
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet herhalen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhalen"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekelijks"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks"])}
    },
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot"])}
  },
  "service": {
    "customGuestPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepersonaliseerde gastenwifi"])},
    "emailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ondersteuning"])},
    "nextDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next day interventie"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonische ondersteuning"])}
  },
  "status": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "notification": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We zijn er om te helpen. Je kan ons telefonisch bereiken."])}
    },
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "standby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stand-by"])},
    "toDeploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen devices"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aandacht"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatiegegevens ophalen..."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is de status van uw internet- en wifi-oplossing voor:"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemen met iets?"])},
    "problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander probleem?"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dringende ondersteuning nodig?"])},
    "buttons": {
      "amplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met amplr"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer uw statuspagina"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De statuspagina is de pagina die uw medewerkers krijgen bij het scannen van de amplr QR-code. \nU kunt alle verschillende ondersteuningsinformatie aan deze pagina toevoegen."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureer pagina"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar de ontwerptool voor uw statuspagina door op de onderstaande knop te klikken"])},
      "noButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft nog geen contactknoppen geconfigureerd"])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactknop toevoegen"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet uw wijzigingen publiceren, anders worden de wijzigingen niet live weergegeven"])}
    },
    "gettingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details ophalen..."])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geïnstalleerd"])},
    "notConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet ingesteld"])},
    "reportMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratierapport ontbreekt"])},
    "liveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geïnstalleerd"])},
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])},
    "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw actieve systemen"])},
    "deviceGroupOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle apparaten in deze systeemgroep zijn online!"])},
    "deviceGroupWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige apparaten in deze systeemgroep zijn momenteel niet verbonden. \nKijk alsjeblieft!"])},
    "deviceGroupOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen van de apparaten in de systeemgroep lijkt online te zijn. \nDit heeft een grote impact. \nControleer de groep."])},
    "deviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaatoverzicht"])},
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaat offline"])},
    "deviceGroupOfflineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit apparaat is niet aangesloten op een stroombron of niet via een kabel of wifi verbonden met internet. \nControleer de aansluitingen. \nIdentificeer het apparaat op basis van het bovenstaande MAC-adres."])}
  },
  "success": {
    "admin": {
      "config": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De configuratie is toegevoegd."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De configuratie is verwijderd."])}
      },
      "customer": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is toegevoegd."])},
        "addedCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is toegevoegd aan de groep."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is verwijderd."])},
        "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is gewijzigd."])},
        "locationCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De locatie is toegevoegd aan het bedrijf."])},
        "userAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is toegevoegd."])}
      },
      "group": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De groep is toegevoegd"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De groep is gewijzigd"])}
      },
      "location": {
        "configCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De configuratie is opgeslaan."])},
        "configUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De configuratie is gewijzigd."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De locatie is verwijderd."])},
        "infoUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen werden opgeslagen."])},
        "installFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen werden opgeslagen."])},
        "updatedVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen werden opgeslagen."])},
        "configReportCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het rapport is opgeslagen"])},
        "commentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw reactie is succesvol toegevoegd"])},
        "commentUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw rapport is bijgewerkt"])},
        "commentDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het rapport is verwijderd"])},
        "controllerLinksCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De controllers zijn geconfigureerd"])}
      },
      "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is verwijderd."])},
      "userLinkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is verwijderd."])},
      "partner": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De partner is toegevoegd"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De partner is verwijderd"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De partner is bijgewerkt"])},
        "usersAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruikers zijn uitgenodigd"])},
        "linkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De uitnodiging is verwijderd"])},
        "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is verwijderd"])},
        "accessAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De toegang is toegevoegd"])},
        "locationDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De locatie is verwijderd"])},
        "customerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De klant is verwijderd"])},
        "groupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De groep is verwijderd"])}
      }
    },
    "auth": {
      "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord succesvol gewijzigd. Je kan inloggen."])},
      "passwordResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check je mailbox voor de resetlink."])}
    },
    "customer": {
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen zijn succesvol opgeslagen."])},
      "logoSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het logo is opgeslagen"])}
    },
    "portalClient": {
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je gegevens werden verwijderd."])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnen enkele ogenblikken worden je gegevens gedownload."])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben je een mail gestuurd."])}
    },
    "user": {
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd naar het klembord"])},
      "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je wachtwoord is succesvol gewijzigd."])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen zijn succesvol opgeslagen."])}
    },
    "users": {
      "userEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen zijn opgeslagen"])},
      "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De uitnodiging is verstuurd"])}
    },
    "clientSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De instellingen van het toestel zijn gewijzigd."])},
    "clientDhcpRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben het vaste IP-adres van deze client verwijderd."])},
    "savedDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw instellingen worden opgeslagen"])},
    "statusButtonSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw instellingen worden opgeslagen"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesvol gekopieerd!"])},
    "partner": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De partner is toegevoegd"])},
      "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De partner is verwijderd"])}
    },
    "referral": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verwijzing is verzonden"])}
    },
    "poeCycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PoE gereset"])},
    "portChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poortwijzigingen toegepast!"])},
    "branding": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opgeslagen"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding gemaakt"])}
    },
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen opgeslagen"])}
  },
  "support": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... We merken enkele problemen. Neem contact met ons op!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel ons"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+32 3 375 60 70"])}
  },
  "tooltip": {
    "portal": {
      "webhooks": {
        "endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De URL die het verzoek ontvangt. Tools zoals zapier.com of typedhook.com bieden webhook-diensten om te integreren met uw CRM of andere automatiseringstools."])},
        "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het shared secret wordt verzonden in de request header ('amplr-wh-secret'), gebruik het om te controleren of het inkomende verzoek afkomstig is van amplr.cloud."])}
      }
    },
    "portalTab": {
      "allToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle aangesloten klanten van vandaag, ook degenen die zich niet hebben aangemeld voor marketing."])},
      "allTimeOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle klanten die vanaf het begin voor marketing hebben gekozen."])},
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-time verbonden klanten, ook degenen die zich niet hebben aangemeld voor marketing."])}
    }
  },
  "userProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je profiel adres"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van je profiel"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])}
    },
    "security": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas hier gevoelige data aan."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiligde gegevens"])}
    },
    "setupTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel multifactorauthenticatie in"])},
    "twoFactorScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan de QR-code met uw authenticator-app (Google Authenticator, 1Password, Bitwarden...). Kun je de code niet scannen? Kopieer en plak de URL!"])},
    "2fa": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveilig uw account met multifactorauthenticatie"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifactorauthenticatie"])},
      "finishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooi de installatie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifactorauthenticatie succesvol geconfigureerd."])},
      "codeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om de 2FA-setup te bevestigen, gelieve te bevestigen door uw nieuw gegenereerde 2FA-code te gebruiken."])}
    }
  },
  "users": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
    "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang toevoegen"])},
    "customer": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bedrijven"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bedrijf"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig permissies"])},
    "group": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle groepen"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een groep"])},
      "noGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen groepen om uit te kiezen"])}
    },
    "location": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle locaties"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een locatie"])}
    },
    "steps": {
      "access": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies aan welke groepen, bedrijven of locaties je de gebruiker toegang wil verlenen."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang geven"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang geven aan de gebruiker"])}
      },
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende stap"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige stap"])}
      },
      "email": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg één of meerdere e-mailadressen toe van personen die je wil toevoegen."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail toevoegen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadressen toevoegen"])}
      },
      "invite": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijk de gegevens na, indien alles correct is kan je de uinodigingen versturen door op de knop te klikken."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodiging versturen"])}
      },
      "permissions": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies welke rechten je wil geven aan de gebruiker."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechten geven"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechten geven aan"])}
      }
    },
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer link"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigingslink"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    },
    "title": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])}
    },
    "type": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])}
    },
    "notification": {
      "lastPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verwijderen van de laatste toestemming in deze tabel zal resulteren in een uitgeschakeld account zonder enige toegang. \nWees voorzichtig bij het verwijderen van deze laatste toestemming!"])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen toegang gedefinieerd voor deze gebruiker. Voeg toegang toe of verwijder het gebruikersaccount."])}
    }
  },
  "validations": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldig e-mailadres"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het paswoord moet minstens 8 tekens lang zijn, een hoofdletter, kleine letter, speciaal karakter bevatten"])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldig telefoonnummer"])},
    "postalCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldige postcode"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht"])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De paswoorden komen niet overeen"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldige URL."])},
    "validVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldig BTW nummer"])},
    "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het nummer moet zich tussen ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), " bevinden."])},
    "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit IP adres bestaat al. Kies een ander adres."])},
    "decimalDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is geen geldig formaat. \nGebruik een punt (.)."])}
  },
  "webhook": {
    "log": {
      "selectCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een request om de details te bekijken"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook log"])}
    }
  },
  "user": {
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker uitnodigen"])}
  },
  "tenants": {
    "table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "deactivateOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveren op"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruimte(s)"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])}
    },
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hou vol! \nWe creëren de huurders..."])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedeactiveerd"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De huurders zijn aangemaakt"])},
    "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurders toevoegen"])},
    "uploadCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer gebruikers uit een bestand"])},
    "addManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatig toevoegen"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "editTenant": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurder bewerken"])},
      "offboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveer huurders door een datum voor deactivering te selecteren of druk op de knop \"nu deactiveren\" om de toegang voor een huurder onmiddellijk te verwijderen."])},
      "chooseDeactivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een datum voor toekomstige deactivering"])},
      "scheduleNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft de deactivering van deze huurder al gepland. \nHeb je dit per ongeluk gedaan of wil je het verwijderen?"])},
      "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveringsdatum verwijderen"])},
      "saveAndSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan en mail verzenden"])},
      "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu deactiveren?"])}
    },
    "selectSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer ruimte"])},
    "importComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben de import succesvol afgerond."])},
    "amountImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe huurders gemaakt"])},
    "errorsOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fouten tijdens het maken"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "edit": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen succesvol opgeslagen."])}
    }
  },
  "tenant": {
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan de QR-code of gebruik de volgende gegevens om verbinding te maken"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paswoord"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi ", _interpolate(_named("name")), ", hier vind je je inloggegevens voor de wifi op"])},
    "csvInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer een CSV-bestand met een koprij met twee kolommen: name, email. \nOp je tweede rij voeg je de naam toe in de eerste kolom en het e-mailadres in de tweede kolom. \nWeet je niet zeker of je dit goed doet? \nDownload uw voorbeeld CSV"])},
    "delete": {
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze tenant wilt verwijderen? \nAls u een huurder verwijdert, worden alle instellingen met betrekking tot de huurder verwijderd."])}
    }
  },
  "locationTypes": {
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huis"])},
    "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horeca"])},
    "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailhandel"])},
    "cohousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohousing"])},
    "coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenwerken"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantoor"])},
    "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportfaciliteit"])}
  },
  "passwordConfirmModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw wachtwoord"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet uw wachtwoord bevestigen voordat u verder kunt gaan."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig wachtwoord"])}
  },
  "ppskNotConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenantbeheer is niet ingeschakeld. \nNeem contact op met amplr-ondersteuning om u te helpen bij het instellen van deze functie."])},
  "global": {
    "fiber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiber"])},
    "vdsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDSL"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen"])}
  },
  "modal": {
    "confirm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je het zeker?"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig het verwijderen door het ingeven van:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt niet de juiste waarde ingevuld"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw actie."])}
    },
    "save": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onder welke naam wil je dit opslaan?"])}
    }
  },
  "partner": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info over de partner"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het adres van de partner"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang toevoegen"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies één of meerdere groepen, klanten of locaties waartoe de partner toegang heeft."])}
    }
  },
  "partnerProfile": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een overzicht van uw gegevens"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw adresgegevens"])}
    }
  },
  "tag": {
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald"])},
    "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbetaald"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlopen"])}
  },
  "referral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help andere ondernemers Amplr te ontdekken en verdien 200 euro korting als ze klant worden!"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer het e-mailadres, de naam en het bedrijf in van de persoon die u wilt uitnodigen. \nWij doen de rest!"])},
    "sector": {
      "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horeca"])},
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementen"])},
      "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailhandel"])}
    },
    "introduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb amplr al aan deze persoon voorgesteld"])}
  },
  "table": {
    "head": {
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productnaam"])}
    }
  },
  "title": {
    "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra producten"])}
  },
  "text": {
    "installationLog": {
      "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijn er extra producten geplaatst die niet in de overeenkomst zitten? Zoek de producten en voeg ze dan toe."])}
    }
  },
  "legelEntities": {
    "belgium": {
      "nv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naamloze vennootschap (NV)"])},
      "bv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besloten vennootschap (BV)"])},
      "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coöperatieve Vennootschap (CV)"])},
      "vzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzw (VZW)"])},
      "vof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatschap onder firma (VOF)"])},
      "commv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beperkte vennootschap (Comm V)"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatschap"])},
      "ivzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale non-profitorganisatie (IVZW)"])}
    },
    "netherland": {
      "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenmanszaak"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatschap"])},
      "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereniging"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stichting"])}
    },
    "france": {
      "sarl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatschappij met beperkte verantwoordelijkheid (SARL)"])},
      "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société par Actions Simplifiée "])},
      "sci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Civile Immobilière"])},
      "sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Anonyme (SA)"])},
      "scop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Coopérative et Participative"])}
    }
  },
  "topology": {
    "deviceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaatgegevens"])},
    "device": {
      "ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht switchpoorten"])},
      "portName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poortnaam"])},
      "selectPortProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies poortprofiel"])}
    },
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bewerken van een apparaat kan ernstige gevolgen hebben voor de werking van uw netwerk. \nWees voorzichtig bij het wijzigen van een parameter."])}
  },
  "device": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestelnaam"])},
    "uptime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uptime van het apparaat"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaat is offline"])},
    "cellular": {
      "plmnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbieder laden"])}
    }
  },
  "financials": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financieel doel voor 2023"])}
  },
  "details": {
    "247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7"])},
    "mailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailsupport"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonische ondersteuning"])},
    "businessHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support: ma t/m vr van 9.00 tot 17.00 uur"])},
    "businessHoursPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support: ma t/m vr van 8.00 tot 20.00 uur"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot dashboard"])},
    "dashboardBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis toegang tot dashboard"])},
    "supportQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteunings-QR"])},
    "maxDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale apparaten: "])},
    "brandedGuestWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastenwifi met branding"])},
    "deviceUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische updates"])}
  },
  "successpage": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je deze pagina ziet, werkt je internetverbinding."])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je een AMPLR-klant?"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelukt!"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen klant en wil je meer info?"])}
  },
  "toast": {
    "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hersteld"])},
    "exportStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het exporteren is gestart. U ontvangt een e-mail wanneer de export gereed is om te downloaden."])}
  },
  "select": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
    "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveranciers"])}
  },
  "public": {
    "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn nog geen systeemgroepen gedefinieerd."])}
  },
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
  "past_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlopen"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
  "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["internetverbinding"])},
  "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
  "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Plan"])},
  "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-licentie"])},
  "serviceError": {
    "verifyProductBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product verschilt van facturatie. \nControleer locatie en/of facturatie!"])},
    "installedButNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service is geïnstalleerd maar niet gefactureerd! \nPas de facturatie aan."])},
    "billedButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product gefactureerd maar niet actief op locatie. \nControleer de locatie-instellingen!"])}
  },
  "message": {
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie heeft geen actief abonnement. \nControleer dit even met de boekhouding."])},
    "sales": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkoopbezoek toegevoegd!"])}
    }
  },
  "sales": {
    "new": {
      "venueDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatiegegevens"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef de details van de locatie."])}
      },
      "contactDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact details"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul de contactgegevens in"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over de locatie"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer info over de locatie"])}
      },
      "extra": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra informatie"])}
      },
      "generalInfo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene informatie"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul het verzoek algemene informatie in"])}
      }
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "filter": {
    "sales": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil een offerte"])},
      "followUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft opvolging nodig"])}
    }
  },
  "search": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten! \nGebruik een andere zoekterm."])}
  },
  "filters": {
    "hasNoPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder betaalmethode"])},
    "hasNoLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder locaties"])}
  },
  "customers": {
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegangsrechten voor deze klant"])},
    "invoices": {
      "forceReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forceer het opnieuw laden van facturen"])}
    }
  },
  "main": {
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])}
  },
  "bonus": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&#127873; Breng aan en bespaar geld op uw volgende factuur!"])},
      "howDoesItWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe werkt het?"])},
      "howDoesItWorkBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kent u bedrijven of personen binnen uw netwerk die mogelijk geïnteresseerd zijn in de diensten van Amplr? \nDan kunt u ze nu eenvoudig naar ons doorverwijzen. \nHet enige wat jij hoeft te doen is de klant enthousiast maken voor onze oplossingen. \nWij zorgen voor de rest!"])},
      "whatsInIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat zit er voor jou in?"])},
      "whatsInItForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om u te bedanken voor uw inspanningen, hebben wij een beloningsprogramma voor succesvolle verwijzingen opgezet. \nAls uw lead een overeenkomst sluit met Amplr, ontvangt u € 200,- korting op uw volgende factuur. \nBovendien krijgt uw doorverwezen lead een aantrekkelijke korting op de eenmalige setupkosten."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class=\"mb-3\">Bij Amplr zijn we van mening dat elk bedrijf recht heeft op een betrouwbare internetverbinding. \nDaarom streven wij er voortdurend naar om onze dienstverlening te verbeteren en ons klantenbestand uit te breiden. \n</p><p class=\"mb-3\">Als gewaardeerde Amplr-klant begrijpen we dat uw unieke netwerk en verbindingen van onschatbare waarde zijn. \n</p><p>Daarom nodigen wij u uit om samen met ons nieuwe locaties te verkennen die, net als de uwe, kunnen profiteren van onze oplossingen via het Amplr Referral Program.</p>"])}
    },
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral details"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul het formulier in om een ​​bedrijf door te verwijzen dat Amplr kan gebruiken voor connectiviteit."])}
    }
  },
  "signal": {
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitstekend"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goed"])},
    "fair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair"])},
    "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwak"])},
    "unstable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onstabiel"])}
  },
  "clients": {
    "table": {
      "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurder"])}
    },
    "reservedClients": {
      "noClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen geconfigureerde clients"])}
    },
    "reservations": {
      "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is al een client met dit IP-adres geconfigureerd"])},
      "macAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is al een client met dit MAC-adres geconfigureerd. \nOm een ​​nieuwe aan te maken, moet u eerst de reservering verwijderen."])}
    }
  },
  "unifios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unifi OS"])},
  "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP-Link Omada"])},
  "unificloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Controller"])},
  "controller": {
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelukt! \nWij kunnen uw controller bereiken."])},
    "connectionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding met uw controller is mislukt. \nZorg ervoor dat uw controller bereikbaar is van buiten het netwerk."])},
    "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We kunnen niet inloggen op de controller. \nZorg ervoor dat de gebruikersnaam en het wachtwoord correct zijn."])},
    "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller met succes toegevoegd"])}
  },
  "branding": {
    "settings": {
      "form": {
        "headerImage": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopafbeelding"])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])}
        }
      }
    },
    "mail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepersonaliseerde instellingen voor e-mails"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaliseer uw e-mails door aangepaste kleuren, headerafbeelding en logo te gebruiken."])}
    }
  },
  "image": {
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij verwerken uw afbeelding"])}
  },
  "deviceGroups": {
    "loadingDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle aangesloten apparaten laden..."])}
  },
  "connectionLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet valt regelmatig uit"])},
  "slowWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trage wifi/gastwifi"])},
  "posIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS-problemen (tickets/verbinding...)"])},
  "supportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteuning van providers is onzin"])},
  "soundIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geluid stottert"])},
  "followUp": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte maken"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bellen"])},
    "notNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet voor nu"])}
  },
  "copy": {
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer naar klembord"])}
  },
  "invalid": {
    "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De API-sleutel lijkt ongeldig te zijn. \nControleer de sleutel en probeer het opnieuw."])}
  },
  "app": {
    "dashboardTab": {
      "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen systeemgroepen geconfigureerd."])}
    }
  },
  "product": {
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product bestaat niet"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product bestaat niet"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product bijgewerkt"])}
  },
  "productModal": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productmodel niet gevonden"])}
  },
  "productModel": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productmodel niet gevonden"])}
  },
  "fluvius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluvius"])},
  "errorCreatingOmadaLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het maken van de Omada-locatie. \nHet bestand is niet compatibel. \nZorg ervoor dat u het juiste bestand (in de configuratieparameters) gebruikt dat overeenkomt met de controllerversie."])}
}