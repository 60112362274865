import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist';
import UserService from '@/services/user';

const defaultState = {
  user: {
    id: null,
    firsname: null,
    name: null,
    isLoggedIn: false,
    lang: 'nl',
    isAdmin: false,
    isPartner: false,
    partnerLogo: null,
    imposterMode: null
  },
  group: {
    id: null,
    permissions: [],
  },
  customer: {
    id: null,
    paymentMethod: null,
    type: null,
    b2b: false,
    freeTier: false,
    permissions: [],
  },
  location: {
    id: null,
    name: '',
    plan: 1,
    paymentMethod: null,
    permissions: [],
  },
  passwordConfirm: {
    modal: false,
    event: null,
  },
};

const vuexLocal = new VuexPersist({
  storage: window.localStorage,
  key: '_dashuser',
  reducer: (state) => ({ user: state.user }),
});

export default createStore({
  state: defaultState,
  mutations: {
    SET_USER(state, payload) {
      state.user = {
        ...state.user,
        ...payload,
        isLoggedIn: true,
      };
    },
    SET_GROUP(state, payload) {
      state.group = payload;
    },
    SET_CUSTOMER(state, payload) {
      state.customer = payload;
      if (
        state.customer.permissions?.includes('partner') ||
        payload.free_tier
      ) {
        state.customer.paymentMethod = 0;
      }
    },
    SET_LOCATION(state, payload) {
      state.location = payload;
      if (state.location.permissions?.includes('partner')) {
        state.location.paymentMethod = 0;
      }
    },
    SET_LANG(state, payload) {
      state.user.lang = payload;
    },
    LOGOUT(state) {
      Object.assign(state, defaultState);
    },
    SHOW_PASSWORD_CONFIRM_MODAL(state) {
      state.passwordConfirm.modal = true;
    },
    SET_EVENT_PASSWORD_CONFIRM_MODAL(state, event) {
      state.passwordConfirm.event = event;
    },
    HIDE_PASSWORD_CONFIRM_MODAL(state) {
      state.passwordConfirm.modal = false;
      state.passwordConfirm.event = null;
    },
  },
  actions: {
    setDefaultLocation(context, payload) {
      context.commit('SET_LOCATION', payload);
      UserService.updateUser({
        default_site: payload.id,
      });
    },
    setDefaultCustomer(context, payload) {
      context.commit('SET_CUSTOMER', payload);
      UserService.updateUser({
        default_customer: payload.id,
      });
    },
    setDefaultGroup(context, payload) {
      context.commit('SET_GROUP', payload);
      UserService.updateUser({
        default_group: payload.id,
      });
    },
  },
  getters: {},
  plugins: [vuexLocal.plugin],
});
