import http from './axios';

class DashboardService {
  getDashboard() {
    return http.get('/dashboard');
  }

  updateDashboard(payload) {
    return http.put(`/dashboard`, payload);
  }
}

export default new DashboardService();
